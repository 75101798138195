import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../style/join-us.scss";

import Footer from "../components/section/footer";
import Header from "../components/section/header";
import AddressLocator from "../components/address-selector/index";
import LandingBgImage from "../../static/content/join-us/zolt-landing-bg.png";
import LogoImage from "../../static/content/zolt-logo.png";

export default function SignUpPage() {

  return (<React.Fragment>
    <Header />
    <div>
      <div
        className="vw-landing section-1"
        style={Object.assign({
          backgroundColor: "#338FCB",
          backgroundSize: "cover",
          // backgroundPosition: "center center",
          backgroundImage: `url(${LandingBgImage})`,
          marginTop: -130,
          paddingTop: 130,
        })}>
        <div>
          <Container>
            <Row style={{minHeight: "calc(100vh - 130px)"}} className="align-items-center">
              <Col>
                <Container>
                  <div className="zolt-title-header">
                    <div className={"d-none d-lg-block"}>
                      <img src={LogoImage} className="join-logo" />
                    </div>
                    <div className="vw-sup-landing">
                      <div className="vw-title">
                        {"Lightning fast internet"}<br/>
                        {"delivered to your"}<br/>
                        {"apartment"}
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col className="vw-sup-locator">
                      <AddressLocator isWhite inline highlight showLocator onRequestClose={()=>{}} />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  </React.Fragment>);
}
